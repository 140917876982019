<template>
  <component v-bind="$attrs" :is="comp" />
</template>

<script setup>
import { getCurrentInstance, computed, ref, watch } from 'vue';
const store = getCurrentInstance().proxy.$store

const comp = computed(() => {

  if (store.getters.getTenant?.pais == 'PT') {
    return () => import( './BorradoresPT.vue' )
  }

  switch (store.getters.getTenant.slug) {
    case '1774':
      case 'zirconite':
      return () => import('./BorradoresIberdrola.vue')
    default:
      return () => import('./BorradoresBase.vue')
  }
  
})

</script>